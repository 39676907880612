import React, { useCallback } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";

const Popup = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const digitalAddress = location.state?.digitalAddress;
  const copyToClipboard = useCallback(() => {
    navigator.clipboard
      .writeText(digitalAddress)
      .then(() => {
        console.log("Digital address copied to clipboard!");
        window.close();

        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
      })
      .catch((err) =>
        console.error("Failed to copy digital address to clipboard: ", err)
      );
  }, [digitalAddress]);

  return (
    <div className="fixed top-0 left-0 w-full h-full overflow-y-auto z-50 bg-opacity-50 flex flex-col  justify-center ">
      <div className="bg-white w-full  lg:mx-auto lg:max-w-lg">
        <div className="space-y-4 p-2 lg:p-4 lg:pt-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="mx-auto w-16 h-16 text-indigo-500"
          >
            <path
              fill-rule="evenodd"
              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <p className="font-bold text-center text-lg tailwind">
            Digital address created successfully!
          </p>
          <p className="font-bold text-center text-lg tailwind text-2xl">
            {digitalAddress}
          </p>
          <p className="text-sm lg:text-base text-center">
            Make sure you copy your digital address first before you close this
            modal
          </p>
          <a
            onClick={copyToClipboard}
            className="w-full cursor px-6 py-4 font-medium transition duration-300 w-full md:w-auto text-center text-white bg-indigo-500 hover:bg-indigo-600 block"
          >
            Copy to clipboard and continue
          </a>
        </div>
      </div>
    </div>
  );
};

export default Popup;

interface PopupProps {
  isOpen: boolean;
  digitalAddress: string;
  onClose: () => void;
}
