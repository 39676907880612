import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import {
  handledataSubmition,
  mapSearch,
  resultMapSearch,
} from "../services/digital-address/api";
import useDebounce from "../utils/hooks";
import { geocode, OutputFormat as GeocodeOutputFormat } from "react-geocode";
import DefaultIcon from "../assets/images/DefaultIcon.png";
import SatelliteIcon from "../assets/images/SatelliteIcon.png";
import { useLocation, useNavigate } from "react-router-dom";
import MapMarkerAnimated from "./MarkerAnimated";

const AddDigitalAddressStep = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [key, setKey] = useState(Date.now());
  const [marketLocation, setMarketLocation] = useState("");
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [placeIdentifier, setPlaceIdentifier] = useState("");
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [zoom, setZoom] = useState<number>(11);
  const [isLoading, setLoading] = useState(false);
  const [isMapLocked, setIsMapLocked] = useState(true);
  const [mapType, setMapType] = useState<"roadmap" | "satellite">("satellite");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [isOpen, setIsOpen] = useState(false);
  const [digitaladdress, setDigitaladdress] = useState<string>("");
  const containerRef = useRef<HTMLDivElement>(null);
  const [bounds, setBounds] = useState<google.maps.LatLngBounds | null>(null);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const geocoderRef = useRef<google.maps.Geocoder | null>(null);
  const [center, setCenter] = useState({
    lat: 1.3733,
    lng: 32.2903,
  });

  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (debouncedSearchQuery === "") return;

    mapSearch(debouncedSearchQuery)
      .then((res) => {
        setSearchResults(res.data.predictions);
      })
      .catch((er) => console.error(er));
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (placeIdentifier) {
      resultMapSearch(placeIdentifier)
        .then((res: any) => {
          const newCenter = {
            lat: res.data.result.geometry.location.lat,
            lng: res.data.result.geometry.location.lng,
          };
          setCenter(newCenter);

          const viewport = res.data.result.geometry.viewport;
          const newBounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(
              viewport.southwest.lat,
              viewport.southwest.lng
            ),
            new google.maps.LatLng(
              viewport.northeast.lat,
              viewport.northeast.lng
            )
          );
          setBounds(newBounds);

          // Update marketLocation with the address from the response
          displayTheNameOfThePlace(newCenter.lat, newCenter.lng);
        })
        .catch((error: any) => {
          console.error("Error fetching place details:", error);
        });
    }
  }, [placeIdentifier]);

  useEffect(() => {
    if (map && bounds) {
      map.fitBounds(bounds, { top: 10, bottom: 10, left: 10, right: 10 });
    }
  }, [map, bounds]);

  const handleApiLoaded = ({ map, maps }: MapsApi) => {
    setMap(map);
    setMapType(google.maps.MapTypeId.ROADMAP);
    setZoom(6);
    geocoderRef.current = new maps.Geocoder();
  };

  const handleMapChange = ({ center, zoom }: MapChangeEvent) => {
    setCenter(center);
    setZoom(zoom);
  };

  const handleDragEnd = () => {
    if (map) {
      const newCenter = map.getCenter();
      setCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
      displayTheNameOfThePlace(newCenter.lat(), newCenter.lng());
    }
  };

  const toggleMapType = () => {
    setMapType((currentType) =>
      currentType === google.maps.MapTypeId.ROADMAP
        ? google.maps.MapTypeId.SATELLITE
        : google.maps.MapTypeId.ROADMAP
    );
  };

  const displayTheNameOfThePlace = (lat: number, lng: number) => {
    geocode("latlng", `${lat},${lng}`, {
      key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
      language: "en",
      region: "us",
      outputFormat: GeocodeOutputFormat.JSON,
    }).then((response) => {
      setMarketLocation(response.plus_code.compound_code);
    });
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showCurrentPosition, showError, {
        enableHighAccuracy: true,
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const showCurrentPosition = (position: any) => {
    setZoom(15);
    const newCenter = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    setCenter(newCenter);
    displayTheNameOfThePlace(newCenter.lat, newCenter.lng);
  };

  const showError = (error: GeolocationPositionError) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.");
        break;
      default:
        alert("An unknown error occurred.");
        break;
    }
  };

  const handleClickOutside = (event: Event) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setSearchQuery("");
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (item: any) => {
    setSearchQuery(item.description);
    setPlaceIdentifier(item.place_id);
    setIsOpen(false);
  };

  const handleSubmit = () => {
    setLoading(true);
    const dataToSend = {
      title: marketLocation,
      lat: center.lat,
      long: center.lng,
      additional_info: additionalInfo,
    };
    handledataSubmition(dataToSend)
      .then((res) => {
        setPopupOpen(true);
        setDigitaladdress(res.data.digital_address);
        setLoading(false);
        navigate("/popup", {
          state: { digitalAddress: res.data.digital_address },
        });
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <section
      id="global"
      className="fixed inset-0 z-50 flex max-h-screen items-center justify-center flex-wrap overflow-y-auto"
    >
      <div className="container px-4 mx-auto">
        <div className="relative bg-white shadow-3xl max-w-4xl mx-auto md:p-8 p-4 overflow-y-auto">
          <div
            className="bg-black/70 absolute top-0 bottom-0 left-0 right-0 z-50 flex justify-center items-center"
            style={{ display: isLoading ? "flex" : "none" }}
          ></div>

          <div className="relative mb-8" ref={containerRef}>
            <div className="flex items-center overflow-hidden border w-full focus-within:border-indigo-500">
              <div className="pl-3.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  aria-hidden="true"
                  className="text-gray-400 w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </div>
              <input
                className="pl-2 pr-3.5 w-full bg-transparent outline-none py-4"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setIsOpen(true);
                }}
                onFocus={() => setIsOpen(true)}
                id="inputsInput7-1"
                type="text"
                placeholder="Search for streets, cities, districts..."
              />
            </div>

            {isOpen && searchResults.length > 0 && (
              <div className="absolute mt-1 w-full p-2 bg-white shadow-lg rounded-b max-h-36 overflow-y-auto z-50">
                <ul>
                  {searchResults.map((item: any) => (
                    <div
                      key={item.placeIdentifier}
                      className="p-2 hover:bg-gray-50 cursor-pointer"
                      onClick={() => handleSelect(item)}
                    >
                      <li>{item.description}</li>
                    </div>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div className="relative mb-8 overflow-hidden h-600">
            <div style={{ height: "480px", width: "100%" }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
                  language: "en",
                }}
                key={key}
                center={center}
                zoom={zoom}
                yesIWantToUseGoogleMapApiInternals
                onChange={handleMapChange}
                onDragEnd={handleDragEnd}
                onGoogleApiLoaded={handleApiLoaded}
                options={{ mapTypeId: mapType }}
              ></GoogleMapReact>

              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-full">
                <MapMarkerAnimated />
              </div>

              <div className="top-0 left-0 w-full h-full flex justify-center items-end pb-4">
                <div className="absolute bottom-0 left-0 w-full flex justify-around p-2">
                  <div className="flex items-center absolute bottom-0 transform right-1/2 translate-x-1/2 flex-wrap pb-4">
                    <div className="w-full px-2">
                      <p className="w-full space-x-2 text-center bg-white mb-4">
                        <span>Coordinates :</span>
                        <span className="font-bold">{center.lat}°N</span>
                        <span className="font-bold">{center.lng}°E</span>
                      </p>
                    </div>

                    <div
                      onClick={() => setIsMapLocked(!isMapLocked)}
                      className="w-1/2 p-2"
                    >
                      <a className="border flex gap-2 items-center bg-white transform p-3 font-medium hover:text-indigo-500 text-sm justify-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          stroke-width="1.5"
                          stroke="currentColor"
                          viewBox="0 0 24.83 24.83"
                          className="w-4 h-4"
                          data-name="Layer 2"
                        >
                          <g>
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12.42.75l-3.18,3.18M12.42.75l3.18,3.18M12.42.75v7.42M.75,12.42l3.18-3.18M.75,12.42l3.18,3.18M.75,12.42h7.42M24.08,12.42l-3.18-3.18M24.08,12.42l-3.18,3.18M24.08,12.42h-7.42M12.42,24.08l-3.18-3.18M12.42,24.08l3.18-3.18M12.42,24.08v-7.42"
                            ></path>
                          </g>
                        </svg>
                        <span className="cursor">Move map manually</span>
                      </a>
                    </div>

                    <div onClick={getLocation} className="w-1/2 p-2">
                      <a className="border flex gap-2 items-center bg-white transform p-3 font-medium hover:text-indigo-500 text-sm justify-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-4 h-4"
                        >
                          <path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3A8.994 8.994 0 0 0 13 3.06V1h-2v2.06A8.994 8.994 0 0 0 3.06 11H1v2h2.06A8.994 8.994 0 0 0 11 20.94V23h2v-2.06A8.994 8.994 0 0 0 20.94 13H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"></path>
                        </svg>
                        <span className="cursor">Get my location</span>
                      </a>
                    </div>
                  </div>

                  <button
                    className="absolute bottom-4 left-4 z-10 p-2 bg-white border border-gray-200 rounded shadow-lg"
                    onClick={toggleMapType}
                  >
                    {mapType === "roadmap" ? (
                      <img
                        className="w-8 h-8"
                        src={SatelliteIcon}
                        alt="Satellite"
                      />
                    ) : (
                      <img
                        className="w-8 h-8"
                        src={DefaultIcon}
                        alt="Default"
                      />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex flex-wrap items-center -m-2 mb-4">
            <div className="w-full p-2 hidden">
              <p className="space-x-2 text-lg">
                <span>Business name :</span>
                <span className="font-bold">
                  Africa Treasures and Tours Limited
                </span>
              </p>
            </div>

            <div className="w-full p-2">
              <div className="w-full flex items-center overflow-hidden border focus-within:border-indigo-500">
                <div className="pl-3.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="text-gray-400 w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    ></path>
                  </svg>
                </div>

                <input
                  className="w-full bg-transparent outline-none py-4 px-3"
                  id="inputsInput7-1"
                  type="text"
                  value={marketLocation}
                  placeholder="Address"
                  onChange={(e) => setMarketLocation(e.target.value)}
                />
              </div>
            </div>

            <div className="w-full p-2">
              <div className="w-full flex items-center overflow-hidden border focus-within:border-indigo-500">
                <div className="pl-3.5 hidden">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="text-gray-400 w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    ></path>
                  </svg>
                </div>

                <input
                  className="w-full bg-transparent outline-none py-4 px-3"
                  id="inputsInput7-1"
                  type="text"
                  placeholder="Additional information"
                  onChange={(e) => {
                    setAdditionalInfo(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div
            onClick={!isLoading ? handleSubmit : undefined}
            className="w-full flex flex-wrap overflow-hidden mb-2 justify-end"
          >
            <a className="cursor px-6 py-4 font-medium transition duration-300 bg-black hover:bg-gray-800 w-full text-center text-white bg-indigo-500 hover:bg-indigo-600">
              Confirm address
            </a>
          </div>

          <p className="text-neutral-500 mb-2 text-xs">
            <span className="">
              This digital address will be automatically created and linked to
              your personal account for effortless future access.{" "}
            </span>
            <a className="cursor hover:underline text-blue-500 hover:text-blue-600">
              Learn more
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default AddDigitalAddressStep;

interface MapsApi {
  map: google.maps.Map;
  maps: typeof google.maps;
}

interface MapChangeEvent {
  center: {
    lat: number;
    lng: number;
  };
  zoom: number;
  bounds: {
    nw: { lat: number; lng: number };
    ne: { lat: number; lng: number };
    sw: { lat: number; lng: number };
    se: { lat: number; lng: number };
  };
  marginBounds: {
    nw: { lat: number; lng: number };
    ne: { lat: number; lng: number };
    sw: { lat: number; lng: number };
    se: { lat: number; lng: number };
  };
  size: {
    width: number;
    height: number;
  };
}

interface GeocodeOptions {
  key: string;
  language: string;
  region: string;
  outputFormat: string;
}

enum LocalOutputFormat {
  JSON = "json",
  XML = "xml",
}
