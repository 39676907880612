import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { handlefileUploads } from "../services/boundries/api";

const DownloadPrishes = () => {
  const [progress, setProgress] = useState(0);

  const onDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const chunkSize = 1024 * 1024;
    const totalChunks = Math.ceil(file.size / chunkSize);
    console.log(file);
    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
      const start = chunkIndex * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      const chunk = file.slice(start, end);
      console.log(chunk);
      const formData = new FormData();
      formData.append("file", chunk);
      handlefileUploads(formData)
        .then((res) => {
          return (progressEvent: ProgressEvent<EventTarget>) => {
            const currentProgress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setProgress(currentProgress);
          };
        })
        .catch((err) => {
          console.error(err);
        });
    }

    console.log("File uploaded successfully");
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  // const handleFilesubmit = () => {
  //   console.log("Uploaded file submit:", file);
  // };
  return (
    <div className="flex items-center justify-center w-full">
      <div>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <p>Drag & drop a GeoJSON file here, or click to select one</p>
        </div>
        {progress > 0 && <p>Upload progress: {progress}%</p>}
      </div>
      {/* <a
        onClick={handleFilesubmit}
        className="cursor mb-4 px-6 py-4 font-medium transition duration-300 w-full md:w-auto text-center text-white bg-indigo-500 hover:bg-indigo-600 block"
      >
        Verify Digital Address
      </a> */}
    </div>
  );
};

export default DownloadPrishes;
