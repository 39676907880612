import React from "react";
import { motion } from "framer-motion";

interface MapMarkerAnimatedProps {
  width?: number;
  height?: number;
  [key: string]: any;
}

const MapMarkerAnimated: React.FC<MapMarkerAnimatedProps> = (props) => {
  const { width = 39.85, height = 40.206 } = props;

  const pulsateVariants = {
    initial: { r: 3.556, opacity: 0.3 },
    animate: {
      opacity: [0.3, 0, 0.3],
      transition: {
        duration: 1,
        repeat: Infinity,
        repeatType: "loop" as const,
      },
    },
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Group_673"
      data-name="Group 673"
      width={width}
      height={height}
      viewBox="0 0 39.85 40.206"
      {...props}
    >
      <g id="Group_672" data-name="Group 672">
        <circle
          id="Ellipse_141"
          data-name="Ellipse 141"
          cx="3.556"
          cy="3.556"
          r="3.556"
          fill="#db0000"
          stroke="#fff"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="0.8"
          transform="translate(16.378 16.359)"
        />
        <motion.circle
          cx="3.556"
          cy="3.556"
          fill="#db0000"
          stroke="#fff"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="0.8"
          variants={pulsateVariants}
          initial="initial"
          animate="animate"
          transform="translate(16.378 16.359)"
        />
        <line
          x1="0"
          y1="0"
          x2="0"
          y2="15"
          stroke="#000"
          strokeWidth="2"
          transform="translate(19.378 0)"
        />
        <line
          x1="0"
          y1="0"
          x2="0"
          y2="15"
          stroke="#000"
          strokeWidth="2"
          transform="translate(19.378 25)"
        />
        <line
          x1="0"
          y1="0"
          x2="15"
          y2="0"
          stroke="#000"
          strokeWidth="2"
          transform="translate(0 19.359)"
        />
        <line
          x1="0"
          y1="0"
          x2="15"
          y2="0"
          stroke="#000"
          strokeWidth="2"
          transform="translate(25 19.359)"
        />
      </g>
    </svg>
  );
};

export default MapMarkerAnimated;
