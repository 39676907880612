import React, { useEffect, useRef, useState } from "react";

import GoogleMapReact from "google-map-react";

import {
  handledataSubmition,
  mapSearch,
  resultMapSearch,
  tracePrishes,
} from "../services/digital-address/api";

import useDebounce from "../utils/hooks";
import { geocode, RequestType, setDefaults } from "react-geocode";

import DefaultIcon from "../assets/images/DefaultIcon.png";

import SatelliteIcon from "../assets/images/SatelliteIcon.png";
import Popup from "../components/api-completed";
import { useLocation, useNavigate } from "react-router-dom";
import boundaryData from "../../public/ug.geojson";

const TestData = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [key, setKey] = useState(Date.now());
  const [marketLocation, setMarketLocation] = useState("");
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [placeIdentifier, setPlaceIdentifier] = useState("");
  const [maps, setMaps] = useState<typeof google.maps | null>(null);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [zoom, setZoom] = useState<number>(11);
  const [isLoading, setLoading] = useState(false);
  const [isMapLocked, setIsMapLocked] = useState(true);
  const [mapType, setMapType] = useState<"roadmap" | "satellite">("satellite");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [isOpen, setIsOpen] = useState(false);
  const [digitaladress, setDigitaladress] = useState<string>("");
  const containerRef = useRef<HTMLDivElement>(null);
  const [bounds, setBounds] = useState<google.maps.LatLngBounds | null>(null);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const geocoderRef = useRef<google.maps.Geocoder | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [boundaries, setBoundaries] = useState<any[]>([]);

  const [fileUrl, setFileUrl] = useState("");
  useEffect(() => {
    setFileUrl("/data/parishList.xlsx");
  }, []);

  const [boundaryData, setBoundaryData] = useState(null);
  const handleClosePopup = () => {
    setPopupOpen(false);
  };
  useEffect(() => {
    setBoundaryData(boundaryData);
  }, []);
  useEffect(() => {
    setKey(Date.now());
  }, []);
  const [center, setCenter] = useState({
    lat: 1.3733,
    lng: 32.2903,
  });
  const [marker, setMarker] = useState({ ...center, text: "Initial Marker" });
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (debouncedSearchQuery === "") return;

    mapSearch(debouncedSearchQuery)
      .then((res) => {
        setSearchResults(res.data.predictions);
      })

      .catch((er) => console.error(er));
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (placeIdentifier) {
      resultMapSearch(placeIdentifier)
        .then((res: any) => {
          setCenter({
            lat: res.data.result.geometry.location.lat,
            lng: res.data.result.geometry.location.lng,
          });

          const viewport = res.data.result.geometry.viewport;
          const newBounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(
              viewport.southwest.lat,
              viewport.southwest.lng
            ),
            new google.maps.LatLng(
              viewport.northeast.lat,
              viewport.northeast.lng
            )
          );

          setBounds(newBounds);
        })
        .catch((error: any) => {
          console.error("Error fetching place details:", error);
        });
    }
  }, [placeIdentifier]);

  useEffect(() => {
    if (map && bounds) {
      map.fitBounds(bounds, { top: 10, bottom: 10, left: 10, right: 10 });
      map.setOptions({
        zoomControl: true,
        scrollwheel: true,
        disableDoubleClickZoom: false,
      });
    }
  }, [bounds]);

  useEffect(() => {
    if (map && bounds) {
      map.fitBounds(bounds);
    }
  }, [map, bounds]);

  useEffect(() => {
    fetch("data/ug.geojson")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setBoundaryData(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching GeoJSON data:", error);
      });
  }, []);
  const drawBoundaries = (features: any, map: google.maps.Map<Element>) => {
    console.log("Drawing boundaries for", features.length, "features");
    features.forEach((feature: any, index: number) => {
      const { type } = feature.geometry;
      let polygons = [];

      console.log(`Processing feature ${index + 1}: Type ${type}`);

      if (type === "Polygon") {
        polygons = [feature.geometry.coordinates];
      } else if (type === "MultiPolygon") {
        polygons = feature.geometry.coordinates;
      }

      polygons.forEach((polygon: any, idx: any) => {
        polygon.forEach((ring: any) => {
          const paths = ring.map((coord: any) => ({
            lat: coord[1],
            lng: coord[0],
          }));

          const boundary = new google.maps.Polygon({
            paths,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
          });

          boundary.setMap(map);
          console.log(`Drawing polygon ${idx + 1} for feature ${index + 1}`);
        });
      });
    });
  };

  const handleApiLoaded = ({ map, maps }: MapsApi) => {
    setMap(map);
    setMapType(google.maps.MapTypeId.ROADMAP);
    setZoom(6);
    geocoderRef.current = new maps.Geocoder();

    fetch("/data/ug.geojson")
      .then((response) => response.json())
      .then((data) => {
        console.log("GeoJSON data loaded:", data);
        setBoundaries(data.features);
        drawBoundaries(data.features, map);
      })
      .catch((error) => console.error("Failed to load boundaries:", error));
    map.setOptions({
      zoomControl: true,
      fullscreenControl: false,
      minZoom: 1,
    });

    if (geocoderRef.current) {
      geocoderRef.current.geocode(
        { address: "New York" },
        (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            console.log(results);
            console.log("API Loaded", map, maps);
          } else {
            console.error(
              "Geocode was not successful for the following reason:",
              status
            );
          }
        }
      );
    }

    if (!maps.Geocoder) {
      console.error("Geocoder API not loaded");
    }
  };

  const toggleMapType = () => {
    setMapType((currentType) =>
      currentType === google.maps.MapTypeId.ROADMAP
        ? google.maps.MapTypeId.SATELLITE
        : google.maps.MapTypeId.ROADMAP
    );
  };

  const displaytheNameOfthePlace = (lat: number, lng: number) => {
    geocode("latlng", lat + "," + lng, {
      key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      language: "en",
      region: "us",
      outputFormat: OutputFormat.JSON,
    }).then((response) => {
      setMarketLocation(response.plus_code.compound_code);
    });
  };
  const geocodeLatLng = (
    geocoder: google.maps.Geocoder,
    lat: number,
    lng: number
  ): Promise<google.maps.GeocoderResult[]> => {
    return new Promise((resolve, reject) => {
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          resolve(results);
        } else if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
          reject(
            new Error(
              "Geocoding succeeded but returned no results. This location may not be recognized."
            )
          );
        } else {
          reject(new Error(`Geocoding failed: ${status}`));
        }
      });
    });
  };
  const handleMapChange = ({ center }: MapChangeEvent) => {
    if (geocoderRef.current) {
      geocodeLatLng(geocoderRef.current, center.lat, center.lng);
    } else {
      console.error("Geocoder is not initialized");
    }
    setMarker({
      ...marker,
      lat: clampLatitude(center.lat),
      lng: clampLongitude(center.lng),
    });

    setCenter({
      lat: clampLatitude(center.lat),
      lng: clampLongitude(center.lng),
    });
    displaytheNameOfthePlace(
      clampLatitude(center.lat),
      clampLongitude(center.lng)
    );
  };

  const changeMapType = (type: google.maps.MapTypeId) => {
    if (map) {
      map.setMapTypeId(type);
    }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showCurrentPosition, showError, {
        enableHighAccuracy: true,
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const showCurrentPosition = (position: any) => {
    setZoom(15);
    const newCenter = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };

    setCenter(newCenter);
  };

  const showError = (error: GeolocationPositionError) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("User denied the request for Geolocation.");

        break;

      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");

        break;

      case error.TIMEOUT:
        alert("The request to get user location timed out.");

        break;

      default:
        alert("An unknown error occurred.");

        break;
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: Event) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setSearchQuery("");
      setIsOpen(false);
    }
  };
  function clampLatitude(lat: number): number {
    return Math.max(-90, Math.min(90, lat));
  }

  const clampLongitude = (lng: number): number => {
    lng = lng % 360;
    if (lng > 180) {
      lng -= 360;
    } else if (lng < -180) {
      lng += 360;
    }
    return lng;
  };
  const handleSelect = (item: any) => {
    setSearchQuery(item.description);
    setPlaceIdentifier(item.place_id);
    setIsOpen(false);
  };
  const handleSubmit = () => {
    setLoading(true);
    const dataToSend = {
      title: marketLocation,
      lat: center.lat,
      long: center.lng,
      additional_info: additionalInfo,
    };
    handledataSubmition(dataToSend)
      .then((res) => {
        setPopupOpen(true);
        setDigitaladress(res.data.digital_address);

        setLoading(false);
        navigate("/popup", {
          state: { digitalAddress: res.data.digital_address },
        });
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  return (
      <section
          id="global"
          className=" relative overflow-hidden h-screen w-screen "
      >
          <div style={{height: "100%", width: "100%"}}>
            <GoogleMapReact
                bootstrapURLKeys={{
                  key: "" + process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                  language: "en",
                }}
                key={key}
                center={center}
                zoom={zoom}
                yesIWantToUseGoogleMapApiInternals
                onChange={handleMapChange}
                onGoogleApiLoaded={handleApiLoaded}
                draggable={isMapLocked}
                options={{
                  mapTypeId: mapType,
                }}
            ></GoogleMapReact>


          </div>
      </section>
  );
};

export default TestData;

interface MapClickEvent {
  x: number;

  y: number;

  lat: number;

  lng: number;

  event: MouseEvent;
}

interface MapsApi {
  map: google.maps.Map;

  maps: typeof google.maps;
}

interface MapChangeEvent {
  center: {
    lat: number;
    lng: number;
  };
  zoom: number;
  bounds: {
    nw: { lat: number; lng: number };
    ne: { lat: number; lng: number };
    sw: { lat: number; lng: number };
    se: { lat: number; lng: number };
  };
  marginBounds: {
    nw: { lat: number; lng: number };
    ne: { lat: number; lng: number };
    sw: { lat: number; lng: number };
    se: { lat: number; lng: number };
  };
  size: {
    width: number;
    height: number;
  };
}

interface GeocodeOptions {
  key: string;
  language: string;
  region: string;
  outputFormat: string;
}

enum OutputFormat {
  JSON = "json",
  XML = "xml",
}
