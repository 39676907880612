import React, { useEffect, useRef, useState } from "react";

import Popup from "../components/api-completed";
import AddDigitalAddressStep from "./add-digitaladress";
import PhoneFieldStep from "../components/PhoneFieldStep";
import HomePage from "./HomePage";
import VerifyCodeStep from "../components/VerifyCodeStep";
import TestData from "./test-data1";
import { Route, Routes, useLocation } from "react-router-dom";
import DownloadPrishes from "../components/DownloadPrishes";

const App = () => {
  return (
    <Routes>
      <Route index path="/" element={<PhoneFieldStep />} />
      <Route path="/verification" element={<VerifyCodeStep />} />
      <Route
        path="/create-digital-address"
        element={<AddDigitalAddressStep />}
      />
      <Route path="/popup" element={<Popup />} />
      <Route path="/test-data" element={<TestData />} />
      <Route path="/add-boundries" element={<DownloadPrishes />} />
    </Routes>
  );
};

export default App;
