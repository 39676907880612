import React, { useEffect, useState } from "react";
import {
  PhoneInput,
  defaultCountries,
  parseCountry,
} from "react-international-phone";
import "react-international-phone/style.css";
import logo from "../ePosta-Logo.svg";
import { isPhoneValid } from "../utils/phoneNumberValidation";
import { loginApi } from "../services/users/api";
import Error from "./Error";
import { useNavigate } from "react-router-dom";

const PhoneFieldStep = () => {
  const [phone, setPhone] = useState<string>("");
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();
  // May needs useMemo or useCallback(nothing mentioned in doc)
  const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return ["ug"].includes(iso2);
  });
  useEffect(() => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
  }, []);
  const isValid = isPhoneValid(phone);

  const goToNextStep = () => {
    if (!isValid) {
      setError("Make sure to set a valid Phone Number");
      return;
    }

    loginApi({ phone_number: phone })
      .then((res) => {
        navigate("/verification", { state: { phone: phone } });
      })
      .catch((er) => {
        console.error(er);
        return;
      });
  };

  useEffect(() => {
    if (error) {
      setError("");
    }
  }, [phone]);

  return (
    <div className=" fixed justify-center top-0 left-0 w-full h-full overflow-y-auto z-50 bg-opacity-50 flex flex-col  lg:justify-center  ">
      <div className="bg-white w-full    lg:mx-auto lg:max-w-lg">
        <div className="space-y-4 p-2 lg:p-4">
          <img src={logo} alt="" className="mx-auto h-16 mx-auto h-16 mb-8" />
          <p className="mb-4 text-sm lg:text-base">
            To add a digital address to your account, please enter your phone
            number. This will help us verify your identity and secure your
            account.
          </p>
          <div>
            <div className="flex border divide-x focus-within:border-indigo-500 ">
              <PhoneInput
                defaultCountry="ug"
                value={phone}
                onChange={(phone) => setPhone(phone)}
                countries={countries}
              />
            </div>
            <Error error={error} />
          </div>
          <button
            onClick={goToNextStep}
            className="!w-full px-6 py-4 font-medium transition duration-300 md:w-auto text-center text-white bg-indigo-500 hover:bg-indigo-600 block"
          >
            Next
          </button>
          <p className="text-xs lg:text-sm">
            <span className="">By clicking "Next", you agree to our </span>
            <a
              href=""
              className="hover:underline text-blue-500 hover:text-blue-600"
            >
              Terms of Service{" "}
            </a>
            <span>and </span>
            <a
              href=""
              className="hover:underline text-blue-500 hover:text-blue-600"
            >
              Privacy Policy.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PhoneFieldStep;
