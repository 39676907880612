import getAxiosInterceptor from "../../utils/axiosInterceptor";

export const mapSearch = async (search: string) => {
  const axiosInterceptor = getAxiosInterceptor();
  return axiosInterceptor.get(`/core/google-maps/autocomplete/${search}/`);
};

export const resultMapSearch = async (place_id: string) => {
  const axiosInterceptor = getAxiosInterceptor();
  return axiosInterceptor.get(`/core/google-maps/place/${place_id}/`);
};
export const handledataSubmition = async (
  data: API.DigitalAddressCreateForm
) => {
  const axiosInterceptor = getAxiosInterceptor();

  return axiosInterceptor.post<API.DigitalAddress>(
    "/addresses/api/digital-addresses/",
    data
  );
};

export const checkDigitalAdress = async (digitalAdress: string) => {
  const axiosInterceptor = getAxiosInterceptor();
  return axiosInterceptor.get<API.DigitalAddress>(
    `/addresses/api/digital-addresses/${digitalAdress}`
  );
};

export const tracePrishes = async () => {
  const axiosInterceptor = getAxiosInterceptor();
  return axiosInterceptor.get<any>(`/addresses/api/parish/`);
};
