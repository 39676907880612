import React, { useEffect, useState } from "react";
import { verifyCodeApi, resentVerifyCodeApi } from "../services/users/api";
import { formatPhoneNumber } from "../utils/phoneNumberValidation";
import Error from "./Error";
import logo from "../ePosta-Logo.svg";
import { useLocation, useNavigate } from "react-router-dom";

const VerifyCodeStep = () => {
  const [verifyCode, setVerifyCode] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [pending, setPending] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const phone = location.state?.phone;
  const [countdown, setCountdown] = useState<number | null>(null);
  // if (phone) {
  //     console.log(phone)
  // }

  const handleVerifyCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 6) return;
    setVerifyCode(e.target.value);
  };

  const handleSubmit = () => {
    verifyCodeApi({
      phone_number: phone,
      login_code: verifyCode,
    })
      .then((res) => {
        console.log(res.data.access);
        localStorage.setItem("access", res.data.access);
        localStorage.setItem("refresh", res.data.refresh);
        navigate("/create-digital-address", { replace: true });
      })
      .catch((er) => {
        console.error(er);
        setError("Verify code not valid or expired!");
      });
  };

  const handleResentVerifyCode = () => {
    if (!pending) {
      setPending(true);
      setCountdown(15);
      resentVerifyCodeApi({
        phone_number: phone,
      })
        .then((res) => {
          console.log(res);
          setPending(true);
          setCountdown(15);
        })
        .catch((er) => {
          console.error(er);
        });
    }
  };

  useEffect(() => {
    let timerId: NodeJS.Timeout | null = null;

    if (countdown !== null) {
      if (countdown > 0) {
        timerId = setInterval(() => {
          setCountdown((prevCountdown) => (prevCountdown as number) - 1);
        }, 1000);
      } else {
        setPending(false);
        setCountdown(null);
      }
    }

    return () => {
      if (timerId) clearInterval(timerId);
    };
  }, [countdown]);
  useEffect(() => {
    if (error) setError("");
  }, [verifyCode]);

  return (
    <div className=" fixed top-0 left-0 w-full h-full overflow-y-auto z-50 bg-opacity-50 flex flex-col justify-center lg:justify-center  ">
      <div className="bg-white w-full lg:mx-auto lg:max-w-lg">
        <div className="space-y-4 p-2 lg:p-4">
          <img src={logo} alt="" className="mx-auto h-16 mb-8" />
          <p className="font-bold text-center text-lg tailwind">
            Enter a verification code
          </p>
          <p className="text-sm lg:text-base text-center">
            A tex message with a verification code was just sent to{" "}
            {formatPhoneNumber(phone)}
          </p>
          <div>
            <div className="w-full flex items-center overflow-hidden border focus-within:border-indigo-500">
              <input
                className="w-full bg-transparent outline-none py-4 px-3 text-center"
                id="inputsInput7-1"
                value={verifyCode}
                onChange={handleVerifyCodeChange}
                type="number"
                maxLength={6}
                placeholder="_ _ _ _ _ _"
              />
            </div>
            <Error error={error} />
          </div>
          <button
            className="!w-full px-6 py-4 font-medium transition duration-300 md:w-auto text-center text-white bg-indigo-500 hover:bg-indigo-600 block"
            onClick={handleSubmit}
            // disabled={!!verifyCode}
          >
            Next
          </button>
          {pending && countdown !== null && (
            <p className="text-xs lg:text-sm text-center">
              <span className="">
                Your verification code has been resent. Please allow a few
                moments for its arrival.
                <b>
                  {" "}
                  If you don't receive it within {countdown}, please try again.
                </b>
              </span>
            </p>
          )}
          {!pending && countdown === null && (
            <p className="text-xs lg:text-sm text-center">
              <span className="">Didn't receive a code? </span>
              <button
                className="hover:underline text-blue-500 hover:text-blue-600"
                onClick={handleResentVerifyCode}
              >
                Resend
              </button>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyCodeStep;
