import getAxiosInterceptor from "../../utils/axiosInterceptor";
export const handlefileUploads = async (data: FormData) => {
  const axiosInterceptor = getAxiosInterceptor();

  return axiosInterceptor.post<File>("/citizen/api/file-upload/", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
